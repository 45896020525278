import './GeneralActionButton.css';
import { Component } from 'react';
import Lottie from 'react-lottie';
import LockSVG from './lock.svg';
import LoadingAnimation from './loading-animation.json';

class GeneralActionButton extends Component {

    state = {
        isCurrentlyClicked: false
    }
 
    onMouseDownHandler = () => {
        this.setState({
            isCurrentlyClicked: true
        })
    }

    onMouseUpHandler = () => {
        this.setState({
            isCurrentlyClicked: false
        })
    }

    renderLottieAnimation = () => {
        let animationOptions = {
            loop: true,
            autoplay: true,
            animationData: LoadingAnimation
        }
        return (
            <div className="lottieContainer">
                <Lottie options={animationOptions} height={40} width={40} />
            </div>
        )
    }

    renderLockContainer = () => {
        let lockClassNames = ["lock"];

        if (this.props.isActive) {
            lockClassNames.push("visible");
        }

        return <div className="lockContainer"><img className={lockClassNames.join(" ")} src={LockSVG} alt="" /></div>
    }

    renderPayWithCardButton = () => {
        let DOM = this.renderLockContainer();
        let buttonTextContainerClassNames = ["buttonTextContainer"];

        if (this.props.isProcessingPayment) {
            DOM = this.renderLottieAnimation()
            buttonTextContainerClassNames.push("withLottiePadding")
        }

        return (
            <div className="buttonContentsContainer">
                <div className={buttonTextContainerClassNames.join(" ")}>{this.props.text}</div>
                {DOM}
            </div>
        )
    }

    render () {
        let payButtonContainerClassNames = ['PayButton'];

        let buttonClassNames = ['button'];
        let buttonContent = this.renderPayWithCardButton();

        if (this.state.isCurrentlyClicked) {
            payButtonContainerClassNames.push('withBackgroundColor');
        }

        if (this.props.isActive) {
            buttonClassNames.push('active');
        }

        return (
            <div className={payButtonContainerClassNames.join(' ')}>
                <button className={buttonClassNames.join(' ')} onMouseDown={this.onMouseDownHandler} onMouseUp={this.onMouseUpHandler} onClick={this.props.onClickHandler} >
                    {buttonContent}
                </button>
            </div>
        )
    }

}

export default GeneralActionButton;