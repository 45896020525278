import { Component } from 'react';
import './GeneralUIElementContainer.css';

class GeneralUIElementContainer extends Component {

    render() {
        return (
            <div className="GeneralUIElementContainer">
                {this.props.children}
            </div>
        );
    }

}

export default GeneralUIElementContainer;