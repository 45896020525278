import './FromAndToPaymentContainer.css';
import { Component } from 'react';
import Separator from '../Separator/Separator.js';

class FromAndToPaymentContainer extends Component {

    render() {
        return (
            <div className="FromAndToPaymentContainer">
                <table>
                    <tbody>
                        <tr>
                            <td className="rawText">To</td>
                            <td style={{width: '100%', lineBreak: 'anywhere'}} className="nameText">Wholesale55</td>
                        </tr>
                    </tbody>
                </table>
                <Separator type="border" />
            </div>
        )
    }

}

export default FromAndToPaymentContainer;