import './CompanyLogo.css'
import { Component } from 'react';
import LogoImage from './wholesale55-pormayor55-logo.png';

class CompanyLogo extends Component {

    render() {
        return (
            <div className="CompanyNameAndLogo">
                <img className="companyLogoImage" src={LogoImage} alt="Wholesale55/PorMayor55 Logo" />
            </div>
        )
    }

}

export default CompanyLogo;