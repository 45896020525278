import './ModalContainer.css';
import { Component } from 'react';

class ModalContainer extends Component {

    render() {
        return (
            <div className="ModalContainer">{this.props.children}</div>
        );
    }

}

export default ModalContainer;