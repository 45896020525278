import './Separator.css';
import { Component } from 'react';

class Separator extends Component {

    render () {
        let classNames = ['separator']

        if (this.props.type === 'separator') {
            classNames.push('asSeparator')
        } else if (this.props.type === 'border') {
            classNames.push('asBorder')
        }

        return (
            <div className={classNames.join(' ')}></div>
        )
    }

}

export default Separator;