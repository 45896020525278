import { Component } from 'react';
import './DateComponent.css';

class DateComponent extends Component {

    getFullDate = () => {
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth();
        let fullMonth = null;
        let year = date.getFullYear();

        switch (month) {
            case 0: fullMonth = "January"
            break
            case 1: fullMonth = "Febuary"
            break
            case 2: fullMonth = "March"
            break
            case 3: fullMonth = "April"
            break
            case 4: fullMonth = "May"
            break
            case 5: fullMonth = "June"
            break
            case 6: fullMonth = "July"
            break
            case 7: fullMonth = "August"
            break
            case 8: fullMonth = "September"
            break
            case 9: fullMonth = "October"
            break
            case 10: fullMonth = "November"
            break
            case 11: fullMonth = "December"
	    break
            default:
                console.log("Date error inside DateComponent.js")
        }

        return fullMonth + ", " + day + " " + year
    }

    render() {
        return (
            <div className="dateText">{this.getFullDate()}</div>
        )
    }

}

export default DateComponent;