import './App.css';
import { Component } from 'react';
import DateComponent from './DateComponent/DateComponent.js';
import GeneralUIElementContainer from './GeneralUIElementContainer/GeneralUIElementContainer.js';
import GeneralActionButton from './GeneralActionButton/GeneralActionButton.js';
import ModalContainer from './ModalContainer/ModalContainer.js';
import AmountToPayTextField from './AmounToPayTextField/AmountToPayTextField.js';
import FromAndToPaymentContainer from './FromAndToPaymentContainer/FromAndToPaymentContainer.js';
import CompanyLogo from './CompanyLogo/CompanyLogo.js';
import PaymentConfirmationModal from './PaymentConfirmationModal/PaymentConfirmationModal.js';
import { loadStripe } from '@stripe/stripe-js';
import FadeIn from 'react-fade-in';
const validator = require('email-validator');

const stripePromise = loadStripe('pk_live_AsQWvHexsO6qoLUBxdpBMPRS');

class App extends Component {

  state = {
    amount: {
      value: "",
      isInErrorState: false
    },
    isPayWithCardButtonActive: false,
    isPaymentRequestProcessing: false
  }

  amountChangedHandler = (event) => {
    let regex = /^(([1-9])((\d{1,3})?)(\.\d{0,2})?)?$/;
    let newAmount = event.target.value;

    if (regex.test(newAmount)) {
      this.setState({
        amount: {
          value: newAmount,
          isInErrorState: false
        }
      }, () => {
        this.handleCheckFormInput()
      })
    } else {
      event.target.value = this.state.amount.value;

      this.setState({
        amount: {
          value: event.target.value + "",
          isInErrorState: event.target.value === "" ? true : false
        }
      }, () => {
        this.handleCheckFormInput()
      })
    }
  }

  handleCheckFormInput = () => {
    if (this.state.amount.value !== "") {
      this.setState({
        isPayWithCardButtonActive: true
      })
    } else {
      this.setState({
        isPayWithCardButtonActive: false
      })
    }
  }

  handleOnBlur = () => {
    let amountToPay = this.state.amount.value;

    if (amountToPay !== "") {
      if (!amountToPay.includes(".")) {
        this.setState({
          amount: {
            value: amountToPay + ".00",
            isInErrorState: false
          }
        })
      } else if (amountToPay.endsWith(".")) {
        this.setState({
          amount: {
            value: amountToPay + "00",
            isInErrorState: false
          }
        })
      } else if (amountToPay.charAt(amountToPay.length - 2) === '.') {
        this.setState({
          amount: {
            value: amountToPay + "0",
            isInErrorState: false
          }
        })
      }
    }
  }

  handlePayButtonClick = () => {
    if (this.state.amount.value === "") {
      this.setState({
        amount: {
          value: "",
          isInErrorState: true
        }
      });
    }
  }

  formSubmissionHandler = () => {
    if (!this.state.isPayWithCardButtonActive) {
      return;
    }

    this.performAPIRequest();
  };

  performAPIRequest = () => {
    let payLoad = {
      amountToPay: this.state.amount.value
    }

    let request = new XMLHttpRequest();
    request.open("POST","/create-payment-intent", true);
    request.setRequestHeader('Content-Type',"application/json");

    request.onload = () => {
      if (request.status == 200) {
        this.performSessionChange(request.response);
        this.setState({
          isPaymentRequestProcessing: false,
          isPayWithCardButtonActive: true
        })
      }
    };

    request.send(JSON.stringify(payLoad));
    this.setState({
      isPaymentRequestProcessing: true,
      isPayWithCardButtonActive: false
    })
  }

  performSessionChange = async (response) => {
    let checkoutID = JSON.parse(response).id
    const stripe = await stripePromise;
    stripe.redirectToCheckout({
      sessionId: checkoutID
    })
  };

  renderGeneralActionButton = () => {
    let buttonText = null;

    buttonText = "Processing...";

    if (this.state.isPaymentRequestProcessing) {
      buttonText = "Processing...";
    } else {
      if (this.state.amount.value === "") {
        buttonText = "Enter Amount To Pay";
      } else {
        buttonText = "Pay $" + this.state.amount.value
      }
    }

    return <GeneralActionButton isActive={this.state.isPayWithCardButtonActive} isProcessingPayment={this.state.isPaymentRequestProcessing} text={buttonText} onClickHandler={this.handlePayButtonClick} />
  }

  renderPaymentForm = () => {
    return (
      <form onSubmit={(e) => {
        e.preventDefault();
        this.formSubmissionHandler();
      }}>
          <FadeIn delay="100" transitionDuration="400">
            <CompanyLogo />
            <ModalContainer>
              <DateComponent />
              <GeneralUIElementContainer>
                <AmountToPayTextField
                  value={this.state.amount.value}
                  textChanged={(event) => this.amountChangedHandler(event)}
                  errorState={this.state.amount.isInErrorState}
                  onBlurHandler = {this.handleOnBlur}
                />
              </GeneralUIElementContainer>
              <GeneralUIElementContainer>
                <FromAndToPaymentContainer />
              </GeneralUIElementContainer>
            </ModalContainer>
          </FadeIn>
    
          <FadeIn delay="500" transitionDuration="400">
            <div>
            <ModalContainer>
              <GeneralUIElementContainer>
                {this.renderGeneralActionButton()}
              </GeneralUIElementContainer>
            </ModalContainer>
            </div>
          </FadeIn>
      </form>
    );
  }

  renderPaymentConfirmationModal = (confirmationCode, amountPaid) => {
    return (
      <FadeIn transitionDuration="400">
        <PaymentConfirmationModal amountPaid={amountPaid} confirmationCode={confirmationCode} />
      </FadeIn>
    )
  }

  render() {
    const location = window.location.href;

    let params = new URLSearchParams(window.location.search);
    const confirmationCode = String(params.get("confirmation_code"))
    const amountPaid = String(params.get("amount_paid"))

    if (location.includes('/payments/payment_confirmation') && confirmationCode !== "null" && amountPaid !== "null") {
      return (
        <div id="Root">
          {this.renderPaymentConfirmationModal(confirmationCode, amountPaid)}
        </div>
      )
    } else {
      return (
        <div id="Root">
          {this.renderPaymentForm()}
        </div>
      )
    }
  }
}

export default App;
